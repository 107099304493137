
import { defineComponent } from "vue";

import appreciaition from "./index.vue";
export default defineComponent({
  name: "appreciations pilote",
  components: { appreciaition },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
});
